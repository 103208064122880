import { Injectable } from '@angular/core';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { User } from 'oidc-client';
import { Observable, Subject } from 'rxjs';
import { TStartupData } from '../../services/startup.service';

@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyService {
  ldClient: LDClient;
  flags: LDFlagSet;
  flagChange: Subject<object> = new Subject<object>();

  user: User;
  config: TStartupData;
  constructor() {
    this.flags = {
      [LaunchDarklyFlagNames.bgRatingCustomerSpecificMargins]: false
    };
  }

  init(config: TStartupData, user: User): Observable<LDFlagSet> {
    return new Observable<LDFlagSet>((observer) => {
      this.user = user;
      const userInfo = {
        key: this.user.profile.name,
        email: this.user.profile.email,
        name: this.user.profile.name
      };

      this.ldClient = initialize(`${config.launchDarklyKey}`, userInfo);

      const onReady = () => {
        this.setFlags();
        observer.next(this.flags);
      }

      const onChange = (flags) => {
        if (flags[LaunchDarklyFlagNames.bgRatingCustomerSpecificMargins] !== undefined) {
          this.flags[LaunchDarklyFlagNames.bgRatingCustomerSpecificMargins] =
            flags[LaunchDarklyFlagNames.bgRatingCustomerSpecificMargins].current;
        }

        this.flagChange.next(this.flags);
        observer.next(this.flags);
      }

      this.ldClient.on('ready', onReady);

      this.ldClient.on('change', onChange);

      return () => {
        this.ldClient.off('ready', onReady);
        this.ldClient.off('change', onChange);
      };
    });

  }

  setFlags() {
    this.flags = this.ldClient.allFlags();
    this.flagChange.next(this.flags);
  }

  changeUser(user) {
    if (user !== 'Anonymous') {
      this.ldClient.identify({ key: user, name: user, anonymous: false });
    } else {
      this.ldClient.identify({ key: 'anon', anonymous: true });
    }
  }
}

export enum LaunchDarklyFlagNames {
  bgRatingCustomerSpecificMargins = 'bgrating-customer-specific-margins'
}
