import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from "@angular/core";
import { CustomerHierarchy } from "../../models/CustomerHierarchy";
import { SelectableSettings } from "@progress/kendo-angular-treelist/dist/es2015/selection/selectable-settings";
import { SelectionItem } from "@progress/kendo-angular-treelist";

@Component({
  selector: "app-customer-hierarchy",
  templateUrl: "./customer-hierarchy.component.html",
  styleUrls: ["./customer-hierarchy.component.scss"],
})
export class CustomerHierarchyComponent implements OnChanges {
  @Input() showSelectColumn: boolean | undefined = false;
  @Input() treeData: CustomerHierarchy[] = [];
  @Input() selectableTreeSettings: SelectableSettings;
  @Input() height: number | undefined = 410;
  @Input() selectedCustomers: CustomerHierarchy[] = [];
  @Output() selectedCustomersChange = new EventEmitter<CustomerHierarchy[]>();

  selectedItems: SelectionItem[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.selectedCustomers) {
      this.selectedItems = changes.selectedCustomers.currentValue
        .map((x: CustomerHierarchy) => {
          return { itemKey: x.customerID };
        });
    }
  }

  handleSelectedItemsChange(newlySelectedItems: SelectionItem[]) {
    let selectedCustomers = this.treeData.filter(x => newlySelectedItems.findIndex(y => y.itemKey === x.customerID) >= 0);

    this.selectedCustomersChange.emit(selectedCustomers);
  }
}
