import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { Customer } from "../models/Customer";

@Injectable({
    providedIn: 'root',
})
export class CustomerSelectionService {

    private _customer$: ReplaySubject<Customer> = new ReplaySubject<Customer>(1);

    public setCustomer(customer: Customer){
        this._customer$.next(customer);
    }

    public getCustomer(){
        return this._customer$.asObservable();
    }

}