import { Injectable, Component } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { StartupService } from "./startup.service";

@Injectable()
export class ShipmentClient {
  private shipmentApiHeaders: HttpHeaders;

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private startupService: StartupService
  ) {
    const enterpriseID =
      this.auth.BlueShipUser && this.auth.BlueShipUser.enterpriseID
        ? this.auth.BlueShipUser.enterpriseID.toString()
        : null;
    const userID =
      this.auth.BlueShipUser && this.auth.BlueShipUser.userID
        ? this.auth.BlueShipUser.userID.toString()
        : null;
    const userName = this.auth.BlueShipUser
      ? this.auth.BlueShipUser.userName
      : null;

    this.shipmentApiHeaders = new HttpHeaders({
      EnterpriseID: enterpriseID,
      UserID: userID,
      UserName: userName,
    });
  }

  public get<T>(endPoint: string): Observable<T> {
    return this.http.get<T>(
      `${this.startupService.shipmentApiUrl}v1/${endPoint}`,
      {
        headers: this.shipmentApiHeaders,
      }
    );
  }
}
