import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { GridModule } from "@progress/kendo-angular-grid";
import { DialogsModule, DialogModule } from "@progress/kendo-angular-dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { TreeListModule } from "@progress/kendo-angular-treelist";
import { StartupService } from "./services/startup.service";
import { HeaderMenuComponent } from "./core/containers/header-menu/header-menu.component";
import { AuthCallbackComponent } from "./core/auth-callback/auth-callback.component";
import { RedirectComponent } from "./core/redirect/redirect.component";
import { JwtInterceptor } from "./jwt.interceptor";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthService } from "./services/auth.service";
import { AuthGuardService } from "./services/auth-guard.service";
import { ShipmentClient } from "./services/ShipmentClient";
import { UploadModule } from '@progress/kendo-angular-upload';
import { LayoutModule } from '@progress/kendo-angular-layout';



export function startupServiceFactory(
  startupService: StartupService
): Function {
  return () => startupService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderMenuComponent,
    AuthCallbackComponent,
    RedirectComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    GridModule,
    DialogsModule,
    ButtonsModule,
    DropDownsModule,
    DateInputsModule,
    InputsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientModule,
    ButtonsModule,
    BrowserAnimationsModule,
    DateInputsModule,
    GridModule,
    DialogModule,
    ReactiveFormsModule,
    LabelModule,
    SharedModule,
    UploadModule,
    LayoutModule,
    TreeListModule
  ],
  providers: [
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    AuthService,
    AuthGuardService,
    ShipmentClient,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
