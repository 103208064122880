export enum MenuTypes {
  Link,
  Category,
}

export class MenuItem {
  Label: String;
  URL: String;
  routerLink: Array<string>;
  type: MenuTypes;
  Children: Array<MenuItem>;
}

export let InitialData = new Array<MenuItem>();

const Track: MenuItem[] = [
  {
    Label: "Invoices",
    URL: "#",
    routerLink: ["invoices"],
    type: MenuTypes.Link,
    Children: [],
  },
];

InitialData = Track;
