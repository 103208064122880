export const OPERATIONS = {
  BGR_CANACCESS_BENCHMARKUPLIFTS: "BGR_CanAccess_BenchmarkUplifts",
  BGR_CANACCESS_BLANKETPROPOSALS: "BGR_CanAccess_BlanketProposals",
  BGR_CANACCESS_CARRIERGLOBALPROFILES: "BGR_CanAccess_CarrierGlobalProfiles",
  BGR_CANACCESS_CARRIERMANAGEMENT: "BGR_CanAccess_CarrierManagement",
  BGR_CANACCESS_CONTRACTMANAGEMENT: "BGR_CanAccess_ContractManagement",
  BGR_CANACCESS_CUSTOMERMANAGEMENT: "BGR_CanAccess_CustomerManagement",
  BGR_CANACCESS_CUSTOMERPROPOSALS: "BGR_CanAccess_CustomerProposals",
  BGR_CANACCESS_QUOTE: "BGR_CanAccess_Quote",
  BGR_CANADD_CUSTOMERBLANKETCONTRACT: "BGR_CanAdd_CustomerBlanketContract",
  BGR_CANADD_CUSTOMERCARRIERINCLUSIONEXCLUSION: "BGR_CanAdd_CustomerCarrierInclusionExclusion",
  BGR_CANADD_CUSTOMERRANK: "BGR_CanAdd_CustomerRank",
  BGR_CANADD_CUSTOMERRANKOVERRIDE: "BGR_CanAdd_CustomerRankOverride",
  BGR_CANADD_CUSTOMERRANKVOLUMEOVERRIDE: "BGR_CanAdd_CustomerRankVolumeOverride",
  BGR_CANADD_CUSTOMERRANKSHIPMENTATTRIBUTEOVERRIDE: "BGR_CanAdd_CustomerRankShipmentAttributeOverride",
  BGR_CANDOWNLOAD_BLANKETCONTRACT: "BGR_CanDownload_BlanketContract",
  BGR_CANDOWNLOAD_CUSTOMERCONTRACT: "BGR_CanDownload_CustomerContract",
  BGR_CANDOWNLOAD_CARRIERGLOBALPROFILE: "BGR_CanDownload_CarrierGlobalProfile",
  BGR_CANEDIT_BLANKETCONTRACT: "BGR_CanEdit_BlanketContract",
  BGR_CANADD_CARRIERGLOBALPROFILES: "BGR_CanAdd_CarrierGlobalProfiles",
  BGR_CANEDIT_CARRIERGLOBALPROFILES: "BGR_CanEdit_CarrierGlobalProfiles",
  BGR_CANEDIT_CUSTOMERBLANKETCONTRACT: "BGR_CanEdit_CustomerBlanketContract",
  BGR_CANEDIT_CUSTOMERCONTRACT: "BGR_CanEdit_CustomerContract",
  BGR_CANEDIT_CUSTOMERRANK: "BGR_CanEdit_CustomerRank",
  BGR_CANEDIT_CUSTOMERRANKOVERRIDE: "BGR_CanEdit_CustomerRankOverride",
  BGR_CANEDIT_CUSTOMERRANKVOLUMEOVERRIDE: "BGR_CanEdit_CustomerRankVolumeOverride",
  BGR_CANEDIT_CUSTOMERRANKSHIPMENTATTRIBUTEOVERRIDE: "BGR_CanEdit_CustomerRankShipmentAttributeOverride",
  BGR_CANREMOVE_CUSTOMERRANK: "BGR_CanRemove_CustomerRank",
  BGR_CANREMOVE_CUSTOMERRANKOVERRIDE: "BGR_CanRemove_CustomerRankOverride",
  BGR_CANREMOVE_CUSTOMERRANKVOLUMEOVERRIDE: "BGR_CanRemove_CustomerRankVolumeOverride",
  BGR_CANREMOVE_CUSTOMERRANKSHIPMENTATTRIBUTEOVERRIDE: "BGR_CanRemove_CustomerRankShipmentAttributeOverride",
  BGR_CANUPLOAD_BLANKETCONTRACT: "BGR_CanUpload_BlanketContract",
  BGR_CANUPLOAD_CUSTOMERCONTRACT: "BGR_CanUpload_CustomerContract",
  BGR_CANEDIT_CUSTOMERCARRIERINCLUSIONEXCLUSION: "BGR_CanEdit_CustomerCarrierInclusionExclusion",
  BGR_CANEDIT_PREFERREDCARRIERUPDATERESTRICTED: "BGR_CanEdit_PreferredCarrierUpdateRestricted",
  BGR_CANREMOVE_CUSTOMERCARRIERINCLUSIONEXCLUSION: "BGR_CanRemove_CustomerCarrierInclusionExclusion",
  BGR_CANACCESS_GLOBALCARRIERINCLUSIONEXCLUSION: "BGR_CanAccess_GlobalCarrierInclusionExclusion",
  BGR_CANEDIT_CONTRACTDATES: "BGR_CanEdit_ContractDates",
  BGR_CANACCESS_MASSEXPIRECARRIER: "BGR_CanAccess_MassExpireCarrier",
  BGR_CANCOPYCONTRACTS_CONTRACTMANAGEMENT: "BGR_CanCopyContracts_ContractManagement",
  BGR_CANADD_CARRIER: "BGR_CanAdd_Carrier",
  BGR_CANEDIT_CARRIER: "BGR_CanEdit_Carrier",
  BGR_CANADD_BLANKETCARRIERCAPACITYRULE: "BGR_CanAdd_BlanketCarrierCapacityRule",
  BGR_CANEDIT_BLANKETCARRIERCAPACITYRULE: "BGR_CanEdit_BlanketCarrierCapacityRule",
  BGR_CANREMOVE_BLANKETCARRIERCAPACITYRULE: "BGR_CanRemove_BlanketCarrierCapacityRule",
  BGR_CANADD_CUSTOMERCARRIERCAPACITYRULE: "BGR_CanAdd_CustomerCarrierCapacityRule",
  BGR_CANEDIT_CUSTOMERCARRIERCAPACITYRULE: "BGR_CanEdit_CustomerCarrierCapacityRule",
  BGR_CANREMOVE_CUSTOMERCARRIERCAPACITYRULE: "BGR_CanRemove_CustomerCarrierCapacityRule",
  BGR_CANADD_CUSTOMERCARRIERLIABILITYRULE: "BGR_CanAdd_CustomerCarrierLiabilityRule",
  BGR_CANEDIT_CUSTOMERCARRIERLIABILITYRULE: "BGR_CanEdit_CustomerCarrierLiabilityRule",
  BGR_CANREMOVE_CUSTOMERCARRIERLIABILITYRULE: "BGR_CanRemove_CustomerCarrierLiabilityRule",
  BGR_CANADD_BLANKETCARRIERLIABILITYRULE: "BGR_CanAdd_BlanketCarrierLiabilityRule",
  BGR_CANEDIT_BLANKETCARRIERLIABILITYRULE: "BGR_CanEdit_BlanketCarrierLiabilityRule",
  BGR_CANREMOVE_BLANKETCARRIERLIABILITYRULE: "BGR_CanRemove_BlanketCarrierLiabilityRule",
  BGR_CANADD_BLANKETCLASSLIABILITYRULE: "BGR_CanAdd_BlanketClassLiabilityRule",
  BGR_CANEDIT_BLANKETCLASSLIABILITYRULE: "BGR_CanEdit_BlanketClassLiabilityRule",
  BGR_CANREMOVE_BLANKETCLASSLIABILITYRULE: "BGR_CanRemove_BlanketClassLiabilityRule",
  BGR_CANADD_CUSTOMERCLASSLIABILITYRULE: "BGR_CanAdd_CustomerClassLiabilityRule",
  BGR_CANEDIT_CUSTOMERCLASSLIABILITYRULE: "BGR_CanEdit_CustomerClassLiabilityRule",
  BGR_CANREMOVE_CUSTOMERCLASSLIABILITYRULE: "BGR_CanRemove_CustomerClassLiabilityRule",
  BGR_CANADD_CARRIERUNITTYPEEXCLUSION: "BGR_CanAdd_CarrierUnitTypeExclusion",
  BGR_CANREMOVE_CARRIERUNITTYPEEXCLUSION: "BGR_CanRemove_CarrierUnitTypeExclusion",
  BGR_CANADD_GLOBALCARRIERINCLUSIONEXCLUSION: "BGR_CanAdd_GlobalCarrierInclusionExclusion",
  BGR_CANEDIT_GLOBALCARRIERINCLUSIONEXCLUSION: "BGR_CanEdit_GlobalCarrierInclusionExclusion",
  BGR_CANREMOVE_GLOBALCARRIERINCLUSIONEXCLUSION: "BGR_CanRemove_GlobalCarrierInclusionExclusion",
  BGR_CANADD_CUSTOMERRANKINDEXDETAIL: "BGR_CanAdd_CustomerRankIndexDetail",
  BGR_CANEDIT_CUSTOMERRANKINDEXDETAIL: "BGR_CanEdit_CustomerRankIndexDetail",
  BGR_CANREMOVE_CUSTOMERRANKINDEXDETAIL: "BGR_CanRemove_CustomerRankIndexDetail",
  BGR_CANADD_CARRIERRANKINDEXDETAIL: "BGR_CanAdd_CarrierRankIndexDetail",
  BGR_CANEDIT_CARRIERRANKINDEXDETAIL: "BGR_CanEdit_CarrierRankIndexDetail",
  BGR_CANREMOVE_CARRIERRANKINDEXDETAIL: "BGR_CanRemove_CarrierRankIndexDetail",
  BGR_CANADD_CUSTOMERPROPOSAL: "BGR_CanAdd_CustomerProposal",
  BGR_CANEDIT_CUSTOMERPROPOSAL: "BGR_CanEdit_CustomerProposal",
  BGR_CANDOWNLOAD_CUSTOMERPROPOSAL: "BGR_CanDownload_CustomerProposal",
  BGR_CANCONVERT_CUSTOMERPROPOSAL: "BGR_CanConvert_CustomerProposal",
  BGR_CANADD_BLANKETPROPOSAL: "BGR_CanAdd_BlanketProposal",
  BGR_CANEDIT_BLANKETPROPOSAL: "BGR_CanEdit_BlanketProposal",
  BGR_CANDOWNLOAD_BLANKETPROPOSAL: "BGR_CanDownload_BlanketProposal",
  BGR_CANCONVERT_BLANKETPROPOSAL: "BGR_CanConvert_BlanketProposal",
  BGR_CANEDIT_RANKINDEX: "BGR_CanEdit_RankIndex",
  BGR_CANADD_RANKINDEX: "BGR_CanAdd_RankIndex",
  BGR_CANADD_CUSTOMERRANKLOCATIONOVERRIDE: "BGR_CanAdd_CustomerRankLocationOverride",
  BGR_CANEDIT_CUSTOMERRANKLOCATIONOVERRIDE: "BGR_CanEdit_CustomerRankLocationOverride",
  BGR_CANREMOVE_CUSTOMERRANKLOCATIONOVERRIDE: "BGR_CanRemove_CustomerRankLocationOverride",
  BGR_CANEDIT_CUSTOMERBYPASSBLANKET: "BGR_Customer_CanEditBypassBlanket",
  BGR_CANEDIT_CUSTOMERDYNAMICRATE: "BGR_Customer_CanEditDynamicRate",
  BGR_CANEDIT_CUSTOMERVOLUMERATE: "BGR_Customer_CanEditVolumeRate",
  BGR_CANMASSEDITCUSTOMERS_CUSTOMERMANAGEMENT: "BGR_CanMassEditCustomers_CustomerManagement",
  BGR_CANADD_DYNAMICCARRIER: "BGR_CanAdd_DynamicCarrier",
  BGR_CANEDIT_DYNAMICCARRIER: "BGR_CanEdit_DynamicCarrier",
  BGR_CANADD_PARCELCARRIER: "BGR_CanAdd_ParcelCarrier",
  BGR_CANEDIT_PARCELCARRIER: "BGR_CanEdit_ParcelCarrier",
  BGR_CANADD_CUSTOMERCARRIERBILLING: "BGR_CanAdd_CustomerCarrierBilling",
  BGR_CANEDIT_CUSTOMERCARRIERBILLING: "BGR_CanEdit_CustomerCarrierBilling",
  BGR_CANREMOVE_CUSTOMERCARRIERBILLING: "BGR_CanRemove_CustomerCarrierBilling",
  BGR_CANADD_BLANKETCARRIERBILLING: "BGR_CanAdd_BlanketCarrierBilling",
  BGR_CANEDIT_BLANKETCARRIERBILLING: "BGR_CanEdit_BlanketCarrierBilling",
  BGR_CANREMOVE_BLANKETCARRIERBILLING: "BGR_CanRemove_BlanketCarrierBilling",
  BGR_CANADD_VOLUMECARRIER: "BGR_CanAdd_VolumeCarrier",
  BGR_CANEDIT_VOLUMECARRIER: "BGR_CanEdit_VolumeCarrier",
  BGR_CANEDIT_GUARANTEERATETYPE: "BGR_CanEdit_GuaranteeRateType",
  BGR_CANADD_BENCHMARKCONTRACT: "BGR_CanAdd_BenchmarkContracts",
  BGR_CANEDIT_BENCHMARKCONTRACT: "BGR_CanEdit_BenchmarkContracts",
  BGR_CANDOWNLOAD_BENCHMARKCONTRACT: "BGR_CanDownload_BenchmarkContracts",
  BGR_CANUPLOAD_BENCHMARKCONTRACT: "BGR_CanUpload_BenchmarkContracts",
  BGR_CANCONVERT_BENCHMARKCONTRACT: "BGR_CanConvert_BenchmarkContracts",
  BGR_CANADD_CUSTOMERACCESSORIALOVERRIDE:"BGR_CanAdd_CustomerAccessorialOverride",
  BGR_CANEDIT_CUSTOMERACCESSORIALOVERRIDE: "BGR_CanEdit_CustomerAccessorialOverride",
  BGR_CANREMOVE_CUSTOMERACCESSORIALOVERRIDE: "BGR_CanRemove_CustomerAccessorialOverride",
  BGR_CANEDIT_PRICINGCONFIGALLFIELDS: "BGR_CanEdit_PricingConfigAllFields",
  BGR_CANEDIT_MINMARGINPRICINGCONFIG: "BGR_CanEdit_MinMarginPricingConfig",
  BGR_CANEDIT_UPLIFTTYPEPRICINGCONFIG: "BGR_CanEdit_UpliftTypePricingConfig",
  BGR_CANEDIT_OPENBOOKPRICINGCONFIG: "BGR_CanEdit_OpenBookPricingConfig",
  BGR_CANEDIT_MINCHARGEUPLIFTPRICINGCONFIG: "BGR_CanEdit_MinChargeUpliftPricingConfig",
  BGR_CANEDIT_ROUNDINGAMT: "BGR_CanEdit_RoundingAmt",
  BGR_CANVIEW_COGSBAND: 'BGR_CanView_COGSBand',
  BGR_CANEDIT_COGSBAND: 'BGR_CanEdit_COGSBand',
  BGR_CANADD_PRICINGCONFIG: 'BGR_CanAdd_PricingConfig',
  BGR_CANEDIT_PRICINGCONFIG: 'BGR_CanEdit_PricingConfig',
  BGR_CANEDIT_PRICINGCONFIGOVERRIDE: "BGR_CanEdit_PricingConfigOverride",
  BGR_CANADD_PRICINGCONFIGOVERRIDE: 'BGR_CanAdd_PricingConfigOverride',
  BGR_CANREMOVE_PRICINGCONFIGOVERRIDE: "BGR_CanRemove_PricingConfigOverride",
  BGR_CANBACKDATE: "BGR_CANBACKDATE",
} as const;
