import { Component, EventEmitter, OnInit, Input, Output } from "@angular/core";
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { AuditHistory } from "src/app/models/AuditHistory";
import { AuditHistoryService } from "src/app/services/audit-history.service";

@Component({
  selector: "app-audit-history",
  templateUrl: "./audit-history.component.html",
  styleUrls: ["./audit-history.component.scss"],
})
export class AuditHistoryComponent extends DialogContentBase implements OnInit {
  @Input() tableName: string;
  @Input() primaryKey: number;

  gridData: AuditHistory[] = [];
  loading = false;

  constructor(private service: AuditHistoryService, dialog: DialogRef) {
    super(dialog);
  }

  ngOnInit() {
    this.getAuditHistory();
    this.loading = true;
  }

  getAuditHistory() {
    if (this.tableName && this.primaryKey) {
      this.service
        .getByPK(this.tableName, this.primaryKey)
        .subscribe((data) => {
          this.gridData = data;
          this.loading = false;
        });
    }
  }

  closeWindow() {
    this.dialog.close();
  }
}
