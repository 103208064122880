import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { LaunchDarklyService } from "../shared/launch-darkly/launch-darkly.service";
import { StartupService } from "./startup.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService, 
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private startupService: StartupService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn) {
      this.authService.showHeader = true;
    
      this.launchDarklyService.init(this.startupService.startupData, this.authService.authUser)
        .subscribe(
          (flags) => {
            this.startupService.launchDarklyFlags = flags;
          });

      if(!this.authService.BlueShipUser){
        this.authService.startAuthentication(state.url) }
      if(this.authService.BlueShipUser.userType && this.authService.BlueShipUser.userType === 'Customer'){
        this.router.navigate(["/redirect"]);
        return false;
      }
      return true;
    }

    this.authService.showHeader = false;
    this.authService.startAuthentication(state.url);
    return false;
  }
}
