import { Injectable } from "@angular/core";
import "rxjs/add/operator/do";
import { Observable } from "rxjs";

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthService } from "./services/auth.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private router: Router) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf("usaepay.com") === -1) {
      request = request.clone({
        setHeaders: {
          Authorization: this.auth.getAuthorizationHeaderValue(),
        },
      });
    }

    if (request.url.indexOf("usaepay.com") === -1 && this.auth.BlueShipUser) {
      request = request.clone({
        setHeaders: {
          Authorization: this.auth.getAuthorizationHeaderValue(),
          UserName: this.auth.BlueShipUser.userName,
          UserType: this.auth.BlueShipUser.userType,
          UserAccountNumber: this.auth.BlueShipUser.userAccountNumber,
        },
      });
    }

    return next.handle(request).do(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (
            event.url &&
            !event.url.includes("auth-callback") &&
            !event.url.includes("api/v1/user") &&
            !event.url.includes("api/enterprise")
          ) {
            this.auth.setAccessTime();
          }
        }
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.auth.startAuthentication();
          }
        }
      }
    );
  }
}
