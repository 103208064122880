import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StartupService } from "./startup.service";
import { AuditHistory } from "../models/AuditHistory";

@Injectable({
  providedIn: "root",
})
export class AuditHistoryService {
  constructor(
    private http: HttpClient,
    private startupService: StartupService
  ) {}

  getByPK(tableName: string, primaryKey: number) {
    return this.http.get<AuditHistory[]>(
      `${this.startupService.ratingApiUrl}/api/AuditHistory/pk?tableName=${tableName}&primaryKey=${primaryKey}`
    );
  }
}
