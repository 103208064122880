import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingPromises: Promise<any>[] = [];
  public get isLoading() { return this.loadingPromises.length > 0; }
  
  public isLoadingSubject = new BehaviorSubject(false);

  constructor() {}

  public add<T>(observable: Observable<T>) {
    const promise = observable.toPromise();
    const prevIsLoading = this.isLoading;
    this.loadingPromises.push(promise);

    promise.finally(() => this.removePromise(promise));

    if (prevIsLoading !== this.isLoading) {
      this.isLoadingSubject.next(this.isLoading);
    }

    return promise;
  }

  public subscribe(callback: (isLoading: boolean) => void) {
    return this.isLoadingSubject.subscribe(callback);
  }

  private removePromise(promise: Promise<any>) {
    const prevIsLoading = this.isLoading;
    this.loadingPromises = this.loadingPromises.filter(x => x !== promise)

    if (prevIsLoading !== this.isLoading) {
      this.isLoadingSubject.next(this.isLoading);
    }
  }
}
