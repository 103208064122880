import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthCallbackComponent } from "./core/auth-callback/auth-callback.component";
import { RedirectComponent } from "./core/redirect/redirect.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "contract-management",
    pathMatch: "full",
  },
  {
    path: "contract-management",
    loadChildren: () =>
      import(`./contract-management/contract-management.module`).then(
        (m) => m.ContractManagementModule
      ),
  },
  {
    path: "carrier-management",
    loadChildren: () =>
      import(`./carrier-management/carrier-management.module`).then(
        (m) => m.CarrierManagementModule
      ),
  },
  {
    path: "customer-management",
    loadChildren: () =>
      import(`./customer-management/customer-management.module`).then(
        (m) => m.CustomerManagementModule
      ),
  },
  {
    path: "matrix-iq",
    loadChildren: () =>
      import(`./matrix-iq/matrix-iq.module`).then(
        (m) => m.MatrixIqModule
      ),
  },
  {
    path: "quote",
    loadChildren: () =>
      import(`./quote/quote.module`).then(
        (m) => m.QuoteModule
      ),
  },
  {
    path: "auth-callback",
    component: AuthCallbackComponent,
  },
  {
    path: "redirect",
    component: RedirectComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
