import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-auth-callback",
  templateUrl: "./auth-callback.component.html",
  styleUrls: ["./auth-callback.component.scss"],
})
export class AuthCallbackComponent implements OnInit {
  isIE: boolean;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.completeAuthentication();
    this.isIE =
      window.navigator.userAgent.indexOf("Trident") !== -1 ||
      window.navigator.userAgent.indexOf("MSIE ") !== -1;
  }
}
