import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { FormControl } from "@angular/forms";
import { StartupService } from "../../../services/startup.service";
import { MenuItem, MenuTypes, InitialData } from "./MenuItems";
import { CustomerSelectionService } from "src/app/services/customer-selection.service";
import { Customer } from "src/app/models/Customer";

@Component({
  selector: "app-header-menu",
  templateUrl: "./header-menu.component.html",
  styleUrls: ["./header-menu.component.scss"],
})
export class HeaderMenuComponent implements OnInit, OnDestroy {
  searchTextBox: FormControl;
  originalText: string;
  isMenuActive: Boolean;
  public user: string;
  enterpriseName = this.authService.EnterpriseProfile.name;
  MenuItems: Array<MenuItem>;
  menuTypes: MenuTypes;
  selectedCustomer: Customer;

  logoSource: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private startupService: StartupService,
    private customerSelectionService: CustomerSelectionService
  ) {}

  ngOnInit() {
    this.isMenuActive = true;
    this.MenuItems = InitialData;
    this.originalText = null;
    this.searchTextBox = new FormControl();
    this.enterpriseName = this.authService.EnterpriseProfile.name;

    if (
      this.authService.BlueShipUser &&
      this.authService.BlueShipUser.selectedEnterpriseID
    ) {
      this.logoSource = `${this.startupService.shipmentApiUrl}v1/enterprise/${this.authService.BlueShipUser.selectedEnterpriseID}/logo`;
    }

    this.authService.setSelectedEnterpriseId(
      this.authService.BlueShipUser.selectedEnterpriseID
    );

    this.authService.$loggedIn.subscribe((enterpriseID) => {
      if (enterpriseID) {
        this.setLogoSource(enterpriseID);
      }
    });

    this.authService.$enterpriseSelected.subscribe((enterprise) => {
      if (enterprise === null) {
        return;
      }
      this.enterpriseName = enterprise.name;
      this.setLogoSource(enterprise.ID);
    });
  }

  setSelectedCustomer() {
    if (!this.selectedCustomer) {
      this.customerSelectionService.setCustomer(null);
    }
    this.customerSelectionService.setCustomer(this.selectedCustomer);
  }

  private setLogoSource(id) {
    this.logoSource = `${this.startupService.shipmentApiUrl}v1/enterprise/${id}/logo`;
  }

  ngOnDestroy() {
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("initials");
  }

  openMenu() {
    const root = document.getElementsByTagName("html")[0];
    this.isMenuActive = !this.isMenuActive;
    if (this.isMenuActive) {
      root.classList.add("clipped");
    } else {
      root.classList.remove("clipped");
    }
  }

  logout() {
    this.authService.logout();
  }

  get userName(): string {
    if (!this.authService.BlueShipUser) {
      return sessionStorage.getItem("userName") || "";
    }
    sessionStorage.setItem("userName", this.authService.BlueShipUser.name);
    return this.authService.BlueShipUser.name;
  }

  get initials(): string {
    if (!this.authService.BlueShipUser) {
      return sessionStorage.getItem("initials") || "";
    }
    const split = this.authService.BlueShipUser.name.split(" ");
    const initials =
      split.length > 1
        ? `${split[0].substring(0, 1)}${split[1].substring(0, 1)}`
        : split[0].substring(0, 2);
    sessionStorage.setItem("initials", initials);
    return initials;
  }

  get showHeader(): boolean {
    return this.authService.showHeader;
  }

  hasContractManagementAccess() {
    return this.authService.hasOperation(
      this.authService.OPERATIONS.BGR_CANACCESS_CONTRACTMANAGEMENT
    );
  }

  hasCarrierManagementAccess() {
    return this.authService.hasOperation(
      this.authService.OPERATIONS.BGR_CANACCESS_CARRIERMANAGEMENT
    );
  }

  hasCustomerManagementAccess() {
    return this.authService.hasOperation(
      this.authService.OPERATIONS.BGR_CANACCESS_CUSTOMERMANAGEMENT
    );
  }

  hasQuoteAccess() {
    return this.authService.hasOperation(
      this.authService.OPERATIONS.BGR_CANACCESS_QUOTE
    );
  }

  branding(): BlueShip.Branding {
    return this.authService.Branding &&
      this.authService.Branding.imageBinary.length > 0
      ? this.authService.Branding
      : null;
  }
}
